/* open-sans-regular - cyrillic-ext_cyrillic_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-cyrillic-ext_cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-cyrillic-ext_cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-cyrillic-ext_cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-cyrillic-ext_cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-cyrillic-ext_cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-cyrillic-ext_cyrillic_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-condensed-300 - cyrillic-ext_cyrillic_latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-condensed-v12-cyrillic-ext_cyrillic_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
       url('../fonts/open-sans-condensed-v12-cyrillic-ext_cyrillic_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-condensed-v12-cyrillic-ext_cyrillic_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-condensed-v12-cyrillic-ext_cyrillic_latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-condensed-v12-cyrillic-ext_cyrillic_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-condensed-v12-cyrillic-ext_cyrillic_latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}

/* quicksand-300 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/quicksand-v7-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Quicksand Light'), local('Quicksand-Light'),
       url('../fonts/quicksand-v7-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/quicksand-v7-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/quicksand-v7-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/quicksand-v7-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/quicksand-v7-latin-300.svg#Quicksand') format('svg'); /* Legacy iOS */
}

