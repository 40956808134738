@import '../styles/colors';
@import '../styles/mixins';

.table {
  max-width: 70%;
  width: 70%;
  max-width: 950px; // TODO spacing
  min-width: $min-width;
  position: relative;
  left: 165px;
  margin: 0 auto;
  font-size: 0;
  box-shadow: 0 0 32px 0px rgb(131, 131, 131);
  margin-bottom: $space-xl;
  margin-top: $space-xl;

  @include handheld {
    // TODO REPEATED
    width: 95%;
    margin: $space-xxxl auto $space-xxl auto; // TODO spacing
    position: static;
  }

  @include largescreen {
    left: 218px;
  }

  .visual-padding {
    height: $space-xxl;
    background: gradient(table-header);
  }
}

@media print {
  .table {
    margin: 0;
    padding: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }
}
