$colors: (
  primary: #1e2223,
  secondary: #aaa,
  darkgray: #696969,
  white: #fff,
  dark: #191919,
  outline: #2b3041
);

@function color($color-name) {
  @return map-get($colors, $color-name);
}

$gradients: (
  thumbnail: linear-gradient(120deg, #393939 5%, #333333 95%),
  table: linear-gradient(90deg, color(primary) 25%, #272731 100%),
  table-header: linear-gradient(90deg, color(dark) 25%, #20202a 100%),
  menu-header: linear-gradient(90deg, #21262a 0%, color(dark) 100%),
  menu: linear-gradient(90deg, #282b2f 5%, #22232a 100%),
);

@function gradient($gradient-name) {
  @return map-get($gradients, $gradient-name);
}


