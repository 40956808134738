@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.table-item--gallery-thumbnail {
  position: relative;
  font-size: 0;
  width: 180px;
  height: 120px;
  background: gradient(thumbnail);

  .pswp-thumbnail {
    background: none;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  @include iexplorer10plus {
    box-sizing: border-box;
    padding: 4px;
  }
}
