@import '../styles/colors';
@import '../styles/mixins';

.table-item--details {
  background: gradient(table);
  color: color(secondary);
  font-size: 1.9rem;
  display: grid;
  grid-template-columns: 1fr 1.2fr 1fr 2fr 1fr 5.5fr 1fr 2.7fr;
  grid-template-rows: 16px 16px;
  align-items: center;
  justify-items: center;
  grid-gap: 4px;
  padding: 20px;


  @include handheld {
    & {
      grid-template-columns: 0.5fr 1.2fr 0 2fr 0.7fr 5fr 0.7fr 1.5fr;
    }
  }

  @media (max-width: 802px) { // it should be exactly 802 to match thumbnail resize
    & {
      grid-template-columns: 0.5fr 1.2fr 0 2fr 0.7fr 5fr 0.7fr 0.7fr;
    }
  }

  .diameter-label,
  .diameter-value,
  .height-label,
  .height-value,
  .amount-label,
  .amount-value,
  .order-label,
  .order-value {
    justify-self: end;
  }


  .article {
    font-size: 3.7rem;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
  .diameter-label {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
  }
  .diameter-value {
    grid-column: 5 / 6;
    grid-row: 1 / 2;
  }
  .height-label {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
  }
  .height-value {
    grid-column: 5 / 6;
    grid-row: 2 / 3;
  }
  .amount-label {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
  }
  .amount-value {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
  }
  .order-label {
    grid-column: 6 / 7;
    grid-row: 2 / 3;
  }
  .order-value {
    grid-column: 7 / 8;
    grid-row: 2 / 3;
  }

  @include iexplorer10plus {
    display: block;

    .article {
      display: inline-block;
      padding-right: 16px;
    }

    .diameter-label,
    .diameter-value,
    .height-label,
    .height-value,
    .amount-label,
    .amount-value,
    .order-label,
    .order-value {
      padding-left: 8px;
      display: inline-block;
    }

    .diameter-value,
    .height-value,
    .amount-value,
    .order-value {
      padding-right: 12px;
    }
  }
}
