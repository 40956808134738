@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.main-footer {
  font-family: 'Open Sans', sans-serif;
  display: none;
//  animation: 1000ms ease-out 0s 1 slide_from_bottom; // ANIMATION
  position: fixed;
  bottom: 0;
  min-width: $min-width;
  width: 100%;
  background-color: color(primary);
  text-align: center;

  // duplicate
  @include handheld {
    display: block;
    .copyright {
      display: inline-block;
      padding: $space-s $space-xs;
      font-size: 1.2em; // FONT
      color: color(darkgray);
    }
  }

  // duplicate
  // @include iexplorer10plus {
  //   display: block;
  //   .copyright {
  //     display: inline-block;
  //     padding: $space-s $space-xs;
  //     font-size: 1.2em; // FONT
  //     color: color(darkgray);
  //   }
  // }
}

@media print {
  .main-footer {
    display: none;
  }
}
