@import '../styles/mixins';
@import '../styles/colors';
@import '../styles/fonts';

.contact-wrapper {
  min-width: $min-width;
}

.contact {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6em; // FONT
  padding: 120px 0 64px; // TODO spacing
  margin-left: 550px;
  max-width: 300px;

  h1,
  p {
    display: block;
    color: black;
  }
  h1 {
    font-size: 2.8rem;
    font-weight: normal;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  @include handheld {
    padding: 32px 32px 96px 32px;
    text-align: center;
    margin: 0 auto;
    margin-top: $space-xxxl;
  }
  @include iexplorer10plus {
    padding: 64px 32px 64px 32px;
    margin: 0 auto;
  }
  @include iosdevices {
    min-width: 200px;
    height: 500px; // is required to make the page correct width and not break navbars
  }
}

a[href^=tel] { // is required for ios safari to make phone numbers not white so they are visible
  @include iosdevices {
    // color: color(primary) !important;
    text-decoration: none !important;
    color: inherit !important;
  }
}

@media print {
  .contact {
    font-size: 1.2em;
    margin: 0;
    width: 400px;
    animation: none;
    text-align: start;
  }
}

