@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.navbar--link {
  font-family: 'Open Sans Condensed', sans-serif; // FONT
  font-size: 2.7rem;
  color: color(secondary);
  display: block;
  padding: $space-s $space-xl;
  transition: transform 400ms, color 300ms;

  &:hover {
    transform: translateX(4px);
    color: color(white);
  }
  &:focus {
    outline: none;
    background-color: color(outline);
  }
  @include handheld {
    &:hover {
      color: color(white);
      transform: none;
    }
  }
}

