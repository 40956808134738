@import '../styles/mixins';
@import '../styles/colors';

* {
  color: #fff;
  margin: 0;
  padding: 0;
}

main,
nav {
  @include iexplorer10plus {
    display: block;
  }
}


html {
  overflow-y: scroll;
  font-size: 10px;

  @include largescreen {
    font-size: 12px;
  }
}

body {
  font-size: 100%;
  background-color: #fffdf1; // COLOR
  font-family: 'Open Sans Condensed', sans-serif;
  cursor: default;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.padding_top_ie {
  // @include iexplorer10plus {
  //   padding-top: 100px;
  // }
}

noscript {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: rgb(240, 225, 23); // COLOR
  z-index: 15;
  display: block;
  font-size: 2rem;
  color: black;
  text-align: center;
  padding: 0.5rem;
}

@media print {
  * {
    color: #000;
  }

  body {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
}
