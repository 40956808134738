@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.navbar--logo {
  background: gradient(menu-header);
  font-size: 4.2rem;
  padding: $space-s $space-m;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: $space-m;
  span {
    color: color(secondary); // COLOR
    text-decoration: none;
    font-family: 'Quicksand', sans-serif; // FONT
    font-weight: 300;
    transition: transform 400ms, color 200ms;
    &:hover {
      color: color(white);
      cursor: pointer;
    }
  }

  @include handheld {
    margin-bottom: 0;
  }
}
