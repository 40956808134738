@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.navbar {
  height: 100vh;
  z-index: 3;
  position: fixed;
  overflow: hidden; // to prevent menu items' background overflowing
  background: gradient(menu);
  // animation: 1000ms ease-out 0s 1 slide_from_left; // ANIMATION
  transition: max-height 600ms cubic-bezier(.62,.16,.25,1.3);

  .navbar--footer {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: $space-m;
    .copyright,
    .language-selector {
      padding: $space-xs $space-s;
      font-size: 1.2rem; // FONT
      color: color(darkgray);
    }

    .language-selector {
      text-decoration: none;
      transition: color 300ms;
      &:hover {
        color: color(white);
      }
      &:focus {
        outline: none;
        background-color: color(outline);
      }
    }
    @include min-height {
      display: none;
    }
    @include handheld {
      display: none;
    }
  }

  // navbar
  @include handheld {
    overflow-y: hidden;
    min-width: $min-width;
    width: 100%;
    max-height: 89px;
    text-align: center;

    &.is-open {
      max-height: 305px;
    }
  }

  // navbar
  // @include iexplorer10plus {
  //   height: auto;
  //   background-color: color(primary);
  //   min-width: $min-width;
  //   position: static;
  //   text-align: center;

  //   .logo {
  //     display: block;
  //     margin: 0;
  //   }
  // }
}

@media print {
  .navbar,
  .navbar--footer {
    display: none;
  }
}
