@import '../styles/mixins';
@import '../styles/colors';
@import '../styles/fonts';

.no-match-view {
  width: 100%;
  color: black;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6em; // FONT
  padding: 110px 0 64px; // TODO spacing
  margin-top: 24px;
  margin-left: 550px;
  max-width: 592px;

  @include handheld {
    padding: 96px 32px 96px 32px;
    text-align: center;
    margin: 0 auto;
    margin-top: $space-xxxl;
  }
  @include iexplorer10plus {
    padding: 64px 32px 64px 32px;
    margin: 0 auto;
  }
}
