@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.language-selector {
  &:focus {
    outline: none;
    background-color: color(outline);
  }

  @include handheld {
    & {
      display: inline-block;
      padding: $space-s $space-xs;
      color: color(darkgray);
      font-size: 1.2em; // FONT
      transition: color 250ms;
      text-decoration: none;
    }

    &:hover {
      color: color(white);
    }
  }
}

@media print {
  .language-selector {
    display: none;
  }
}
