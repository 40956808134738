@import '../styles/colors';

.table-item--gallery-thumbnail-details {
  pointer-events: none;
  display: block;
  font-family: 'Open Sans Condensed', sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 71%;
  margin: 8px;
  padding: 4px;
  background-color: color(dark);
  color: color(white);
  transition: transform 250ms ease-out;
  transform-origin: 0% 0%;
  transform: scale(0);
  opacity: 0.95;

  & span {
    font-weight: bold;
    opacity: 1;
    font-size: 1.5rem; // FONT
  }

  &:hover {
    transform: scale(1.2);
  }
}

.table-item--gallery-thumbnail:hover .table-item--gallery-thumbnail-details {
  transform: scale(1.2);
}

@media print {
  .table-item--gallery-thumbnail-details {
    display: none;
  }
}
