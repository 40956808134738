// Cues to change later
// DELETE
// FONT
// COLOR
// SPACING
// REPEATED
// ANIMATION

$space-xs: 8px;
$space-s: 16px;
$space-m: 28px;
$space-l: 36px;
$space-xl: 48px;
$space-xxl: 64px;
$space-xxxl: 96px;

$small-device: 1200px; // devices below 1200px
$large-device: 2000px;
$min-nav-height: 420px;
$min-width: 580px;

@mixin min-height {
  // mixin for hiding navbar_footer if screen height is too small
  @media (max-height: #{$min-nav-height}) {
    @content;
  }
}
@mixin handheld {
  @media (max-width: #{$small-device}) {
    @content;
  }
}
@mixin largescreen {
  @media (min-width: #{$large-device}) {
    @content;
  }
}
@mixin iexplorer10plus {
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}
@mixin iosdevices {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

