@import '../styles/colors';

.totop-button {
  z-index: 2;
  display: block;
  position: fixed;
  bottom: 7%;
  right: 7%;
  background-color: rgba(25, 25, 25, 0.3);
  text-decoration: none;
  width: 50px;
  height: 50px;
  text-align: center;
  transition: background 250ms;
  border-radius: 50%;
  &:hover {
    background-color: rgba(25, 25, 25, 0.8);
  }
  &:focus {
    outline: none;
    background-color: color(outline);
  }
}

@media print {
  .totop-button {
    display: none;
  }
}
