@import '../styles/colors';
@import '../styles/mixins';
@import '../styles/animations';

.table-item--gallery {
  background: gradient(table);

  .pswp-thumbnails {
    display: grid;
    grid-gap: 4px;
    // those two deal with the gap below the image
    text-align: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 180px);
    padding: $space-s $space-xs; // SPACING
  }

  @include iexplorer10plus {
    display: block;

   .pswp-thumbnail {
      display: inline-block;
    }
  }

  @include iosdevices {
    // display: inline-block; // for iphone 4S safari to display table correctly
  }
}

